import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import { LinkButton } from '../components/_parts/LinkButton'

import '../scss/styles.scss'

const IndexPage = () => (
  <Layout className="page-accueil">
    <SEO title="Accueil" />
    <div className="home-container">
      <h1 className="page-title">
        <span className="page-title__red-line">Je crée des </span>
        <br />
        <span className="page-title__hollow-bold">
          Expériences <span className="page-title__dot red">.</span>
        </span>
      </h1>
      <hr style={{ border: "solid #B8B8B8 1px", width: "85%", margin: "0 auto" }} />
      <h2 className="my-name">
        <span className="my-name__first-name">Olivier </span>
        <br />
        <span className="my-name__last-name">Papineau</span>
      </h2>
      <h3 className="job-title">Développeur web</h3>
    </div>


    <LinkButton title="JE PIQUE TA CURIOSITÉ ?" page="a-propos" />
  </Layout>
)

export default IndexPage
